import Utils from "App/js/utils.js";

class Logo {
    constructor(project, property, selector) {
        this.url = project[property];
        this.src = this.url;
        this.container = document.querySelector(selector);
        this.img = this.container.querySelector(".img");
    }
    show() {
        if(this.src) {
            this.img.src = this.src;
            Utils.removeClass(this.container, "hidden");
        }
    }
}
export default Logo;