export default {
    pl : {
        "txtStart": "Rozpocznij",
        "txtNickMaxLength": "Nick nie może być dłuższy niż ",
        "txtChars": " znaków",
        "txtNickIsRequired": "Nick jest wymagany",
        "txtConversation": "Rozmowa",
        "txtConversationWith": "Rozmowa z ",
        "txtCommunicationError": "Wystąpił niespodziewany bląd w komunikacji.",
        "txtMaxMessageLength": "maksymalna długość wiadomości to ",
        "txtServerNotAvailable": "Server jest nieosiągalny.",
        "txtNoPhotosInWalk": "Spacer nie zawiera żadnych zdjęć.",
        "txtWalkLoadingFailed": "Nie udało się załadować spaceru.",
        "txtCallFailure": "Nie udało się nawiązać połączenia glosowego. Jeśli korzystasz z telefonu upewnij się, że masz wlączone WiFi.",
        "txtChatInterrupted": "Rozmowa została przerwana.",
        "txtDefaultName": "Nowy klient",
        "txtDefaultVisitor": "Zwiedzający",
        "txtPokeTitle": "Zaproszenie do rozmowy",
        "txtRefresh": "Odśwież",
        "txtTryAgain": "Spróbuj ponownie",
        "txtLeadPolicy": "Wyrażam zgodę na przetwarzanie przez administratora danych osobowych, firmę {company} znajdującą się pod adresem {address}, {zip_code} {city}, danych wysłanych w formularzu kontaktowym w celu udzielenia odpowiedzi na wysłane zapytanie."
    },
    en: {
        "txtStart": "Begin",
        "txtNickMaxLength": "Nick can't be longer than ",
        "txtChars": " characters",
        "txtNickIsRequired": "Nick is required",
        "txtConversation": "Conversation",
        "txtConversationWith": "Conversation with ",
        "txtCommunicationError": "There was an unexpected error in communication.",
        "txtMaxMessageLength": "the maximum message length is ",
        "txtServerNotAvailable": "Server is not available.",
        "txtNoPhotosInWalk": "The walk does not contain any photos.",
        "txtWalkLoadingFailed": "Failed to load the walk.",
        "txtCallFailure": "A voice call could not be made. If you use the phone, make sure you have WiFi enabled.",
        "txtChatInterrupted": "The call was interrupted.",
        "txtDefaultName": "New client",
        "txtDefaultVisitor": "Visitor",
        "txtPokeTitle": "Invitation to talk",
        "txtRefresh": "Refresh",
        "txtTryAgain": "Try again",
        "txtLeadPolicy": "Wyrażam zgodę na przetwarzanie przez administratora danych osobowych, firmę {company} znajdującą się pod adresem {address} {zip_code} {city}, danych wysłanych w formularzu kontaktowym w celu udzielenia odpowiedzi na wysłane zapytanie."
    },
}