import Utils from "./utils";
class Fullscreen {
  constructor(selector) {
    this.button = document.querySelector(selector);
    Utils.registerEvent(this.button, "click", this.toggle.bind(this));
    this.container = document.documentElement;
    this.expandIcon = document.querySelector(".icon-expand");
    this.shrinkIcon = document.querySelector(".icon-shrink");
  }

  toggle() {
    if (this.isEnabled()) {
      this.disable();
    } else {
      this.enable(this.container);
    }
  }
  enable(element) {
    this.expandIcon.classList.add("hidden");
    this.shrinkIcon.classList.remove("hidden");
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
    else if (element.msRequestFullscreen) element.msRequestFullscreen();
  }
  disable() {
    this.shrinkIcon.classList.add("hidden");
    this.expandIcon.classList.remove("hidden");
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
  }

  getCurrent() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement ||
      null
    );
  }

  isEnabled() {
    var full_screen_element = this.getCurrent();

    // If no element is in full-screen
    return full_screen_element !== null;
  }

  register(callback) {
    document.addEventListener(
      "fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange",
      callback
    );
  }
}
export default Fullscreen;
