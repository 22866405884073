import i18n from "../config/i18n";
import loaderTemplate from "../pages/loader.html";

class Utils {
  addClass(control, css) {
    if (css) {
      control.classList.add(css.trim());
    }
    /*if (control.className.indexOf(css) < 0) {
            control.className += css;
        }*/
  }
  removeClass(control, css) {
    if (css) {
      control.classList.remove(css.trim());
    }
    /*const index = control.className.indexOf(css);
        if (index >= 0) {
            control.className = control.className.substr(0, index);
        }*/
  }
  checkClass(control, css) {
    if (css) {
      return control.classList.contains(css.trim());
    }
  }
  setVisibility(control, visibility) {
    if ((!this.checkClass(control, "hidden")) !== visibility) {
      if (visibility) {
        this.removeClass(control, "hidden");
      } else {
        this.addClass(control, "hidden");
      }
    }
  }
  registerEvent(sender, eventType, handler) {
    if (eventType === "click") {
      let touchEvent = "ontouchend" in window ? "touchend" : "click";
      sender.addEventListener(touchEvent, handler);
    } else {
      sender.addEventListener(eventType, handler);
    }
  }
  getParameterValue(parameter, def) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(parameter)) {
      return urlParams.get(parameter);
    } else {
      return def || null;
    }
  }
  isObject(obj) {
    return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
  }
  translateAttributes(element) {
    const toTranslation = element?.querySelectorAll("*[data-translate]") || [];
    toTranslation.forEach((node) => {
      const attributeToTranslate = node.dataset.translate;
      if (
        attributeToTranslate in node &&
        typeof node[attributeToTranslate] === "string"
      ) {
        node[attributeToTranslate] = i18n.t(node[attributeToTranslate]);
      }
    });
  }
  getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getProjectLang() {
    const languageFromNavigator = navigator?.language?.split("-")?.[0];
    const languageFromQuery = this.getParameterValue("lang");
    const languageFromCookie = this.getCookie("i18next");

    if (languageFromNavigator) {
      return languageFromNavigator;
    } else if (languageFromQuery && languageFromQuery !== "") {
      return languageFromQuery;
    } else if (languageFromCookie) {
      return languageFromCookie;
    } else {
      return "en";
    }
  }
  getProjectCreationLanguage() {
    const languageFromQuery = this.getParameterValue("lang");
    return languageFromQuery || this.getProjectLang();
  }
  async isPermissionDenied(permissionName) {
    if (navigator.permissions) {
      try {
        const result = await navigator.permissions.query({
          name: permissionName,
        });
        return result.state === "denied" || result.state === "prompt";
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  showLoader() {
    const backdrop = document.createElement("div");
    backdrop.classList.add("backdrop");

    const loader = document.createElement("div");
    loader.innerHTML = loaderTemplate;

    document.body.appendChild(backdrop);
    document.body.appendChild(loader);

    return () => {
      backdrop.remove();
      loader.remove();
    };
  }
}
export default new Utils();
