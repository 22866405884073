// import Quill from 'quill';
// import theme from 'quill/dist/quill.snow.css';
import Utils from "App/js/utils.js";
import { Bus } from "App/js/simple-bus.js";
import { INTRO_CLOSED } from "App/js/events.js";
import Logo from "App/js/logo";
import i18n from "../config/i18n";

class Intro {
  constructor(container, minMode, config) {
    this.minMode = minMode;
    this.active = true;
    this.container = container.querySelector(".intro");
    this.config = config;
  }
  show(project, status, onClick) {
    const introDescription = document.querySelector(".intro-description");
    if (this.config.OgImage) {
      this.container.style.backgroundImage = `url(${this.config.OgImage})`;
    }
    const title = project.Name;
    const description = project.Description;
    const logoUrl = project.GroupLogoUrl;
    this.status = status;
    /*const control = new Quill('.placeholder', {
            modules: {
                toolbar: null
            },
            readOnly: true,
            theme: 'snow'
        });
        const content = JSON.parse(description);
        control.setContents(content);*/

    if (!this.minMode) {
      //// logo w intro
      //new Logo(project, "GroupLogoUrl", ".intro").show();

      // logo w intro
      if (project.GroupLogoUrl !== null) {
        new Logo(project, "GroupLogoUrl", ".company-logo").show();
      } else {
        Utils.removeClass(document.querySelector(".img360"), "hidden");
      }

      // intro - avatar
      new Logo(project, "OwnerAvatarUrl", ".owner-avatar").show();
    }

    //Utils.removeClass(logo, 'hidden');
    const titleControl = this.container.querySelector(".title");
    titleControl.innerText = title;

    const infoControl = this.container.querySelector(".info");
    if (!this.minMode) {
      infoControl.innerText = i18n.t("txtIntroDescription");
    } else {
      Utils.addClass(infoControl, " hidden");
    }

    /*this.showPlay(onClick);*/
    this.showStartButton(onClick);
    Utils.addClass(this.container, " visible");
    if (this.container.clientHeight <= 500) {
      this.container.classList.add("isMobile");
    }
    window.addEventListener("resize", (e) => {
      if (e.target.innerHeight <= 500) {
        this.container.classList.add("isMobile");
      }
      if (e.target.innerHeight > 500) {
        this.container.classList.remove("isMobile");
      }
    });

    /*const desc = this.container.querySelector(".description");*/
    /*const placeholder = this.container.querySelector(".placeholder");
        if (placeholder.clientHeight > desc.clientHeight) {
            const last = this.getLastVisibleChild(desc, this.container.clientHeight - 18);//elipsis height
            const top = last.getBoundingClientRect().top;
            desc.setAttribute("style", `bottom: ${this.container.clientHeight - top}px`);
            Utils.addClass(last, ' hidden');
            const elipsis = document.querySelector(".elipsis");
            Utils.addClass(elipsis, ' visible');
        }*/
  }
  showLoadFailed(serverNotResponse) {
    const titleControl = this.container.querySelector(".title");
    titleControl.innerText = "Not found";
    const infoControl = this.container.querySelector(".info");

    if (serverNotResponse) {
      infoControl.innerText = i18n.t("txtServerError");
    } else {
      infoControl.innerText = i18n.t("txtWalkDisabled");
    }

    Utils.addClass(this.container, " visible");

    const button = this.container.querySelector(".start-button");
    button.remove();
    //let buttonText = 'Wirtualny spacer nie istnieje lub został usunięty.';
    //button.innerText = buttonText;
  }
  getLastVisibleChild(control, max) {
    if (control.children.length === 0) {
      return control;
    }
    for (let i = 0; i < control.children.length; i++) {
      const child = control.children[i];
      const bottom = child.getBoundingClientRect().bottom;
      if (bottom > max) {
        return this.getLastVisibleChild(child, max);
      }
    }
    return null;
  }
  hide(cb) {
    this.active = false;
    Utils.removeClass(this.container, " visible");
    // this.container.parentElement.style.display = 'none';
    document.querySelector(".watermark").classList.remove("watermark--intro");
    let viewport = document.getElementById("viewport");
    viewport.classList.remove("blur");
    Bus.post(INTRO_CLOSED);
    if (typeof cb === "function") {
      cb(this);
    }
  }
  showPlay(onClick) {
    const button = this.container.querySelector(".play");
    if (!button) {
      console.warn("Play button does not exist.");
      return;
    }
    Utils.addClass(button, " visible");
    button.addEventListener("click", onClick);
  }
  showStartButton(onClick) {
    const container = this.container.querySelector(".intro-description");
    const button = this.container.querySelector(".start-button");
    let buttonText = i18n.t("txtWalkNotAvailable");

    if (this.status == 1) {
      container.innerText = "";
      var node = document.createElement("p");
      node.classList = "info";
      node.innerText = i18n.t("txtWalkDisabled");
      container.appendChild(node);
    } else if (this.status == 2) {
      buttonText = i18n.t("txtStartBtnText");
      button.addEventListener("click", onClick);
      Utils.addClass(button, "active");
    } else {
      console.warn("Status: " + this.status);
    }
    button.innerText = buttonText;

    var node = document.createElement("i");
    node.classList.add("fas");
    node.classList.add("fa-play-circle");
    button.appendChild(node);
  }
}

export default Intro;
