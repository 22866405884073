import dayjs from "dayjs";
import {
  CHAT_STARTED,
  FIRST_VISIT_SCHENE,
  INTRO_CLOSED,
  MEDIA_PREVIEW_CLOSED,
  MEDIA_PREVIEW_OPENED,
  SCENE_CHANGED,
  VIEW_CHANGED,
} from "./events";
import { Bus } from "./simple-bus";

export default class ActivityTracker {
  constructor(config) {
    this.config = config;
    this.currentSceneId = config?.StartingPhotoId ?? config?.Photos?.[0]?.Id;
    this.visitedRooms = {};
    this.visitedRooms[this.currentSceneId] = {
      time: 0,
    };
    this.lastSceneChange = dayjs();
    this.lastActivityCallbacks = [];

    this.isMediaPreviewOpen = false;
    this.isChatStarted = false;

    this.handleEvents();
  }

  handleEvents() {
    Bus.subscribe(VIEW_CHANGED, (data) => {
      this.onNewActivity(VIEW_CHANGED, data);
    });

    Bus.subscribe(SCENE_CHANGED, (data) => {
      if (!(data?.photoId in this.visitedRooms)) {
        this.onNewActivity(FIRST_VISIT_SCHENE, data);
        this.visitedRooms[data?.photoId] = {
          time: 0,
        };
      }
      this.visitedRooms[this.currentSceneId].time += dayjs().diff(
        this.lastSceneChange,
        "second"
      );
      this.currentSceneId = data?.photoId;
      this.lastSceneChange = dayjs();
      this.onNewActivity(SCENE_CHANGED, data);
    });

    Bus.subscribe(MEDIA_PREVIEW_OPENED, (data) => {
      this.isMediaPreviewOpen = true;
      this.onNewActivity(MEDIA_PREVIEW_OPENED, data);
    });

    Bus.subscribe(MEDIA_PREVIEW_CLOSED, (data) => {
      this.isMediaPreviewOpen = false;
      this.onNewActivity(MEDIA_PREVIEW_CLOSED, data);
    });

    Bus.subscribe(CHAT_STARTED, (data) => {
      this.isChatStarted = true;
      this.onNewActivity(CHAT_STARTED, data);
    });
  }

  onNewActivity(type, data) {
    console.log(`%c[event] ${type}`, "color: #87A2FB");
    this.lastActivityCallbacks.forEach((activity) => {
      if (activity.type && type !== activity.type) return;
      if (activity.timeout) {
        clearTimeout(activity.timeout);
      }
      if (activity.time > 0) {
        activity.timeout = setTimeout(
          () => activity.callback(data),
          activity.time
        );
      } else {
        activity.callback(data);
      }
    });
  }

  afterLastActivity(type, callback, time, fromNextEvent = true) {
    this.lastActivityCallbacks.push({
      callback,
      time,
      type,
      timeout: fromNextEvent ? null : setTimeout(callback, time),
    });
  }

  getUnvisitedRoom() {
    const unvisitedRooms = this.config.Photos.filter(
      (photo) => !(photo.Id in this.visitedRooms)
    );
    return unvisitedRooms.length
      ? unvisitedRooms[Math.floor(Math.random() * unvisitedRooms.length)]
      : null;
  }

  wasVisited(sceneId, time = 0) {
    return (
      sceneId in this.visitedRooms && this.visitedRooms[sceneId].time > time
    );
  }
}
