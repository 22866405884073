class EventBus {
    constructor() {
        this.eventCallbacksPairs = [];
    }

    subscribe(eventType, callback) {
        const eventCallbacksPair = this._findEventCallbacksPair(eventType);

        if (eventCallbacksPair) {
            for (let existing of eventCallbacksPair.callbacks) {
                if (existing.toString() === callback.toString()) {
                    return;
                }
            }
            eventCallbacksPair.callbacks.push(callback);
        } else {
            this.eventCallbacksPairs.push(new EventCallbacksPair(eventType, callback));
        }
    }

    post(eventType, args) {
        const eventCallbacksPair = this._findEventCallbacksPair(eventType);
        
        if (!eventCallbacksPair) {
            console.error("no subscribers for event " + eventType);
            return;
        }

        eventCallbacksPair.callbacks.forEach(callback => callback(args));
    }

    _findEventCallbacksPair(eventType) {
        return this.eventCallbacksPairs.find(eventObject => eventObject.eventType === eventType);
    }
}

class EventCallbacksPair {
    constructor(eventType, callback) {
        this.eventType = eventType;
        this.callbacks = [callback];
    }
}

export const Bus = new EventBus();
export const GOT_USER_MEDIA = "GOT_USER_MEDIA";
export const INVOKE = "INVOKE";
export const GOT_REMOTE_STREAM = "GOT_REMOTE_STREAM";
export const ERROR = "ERROR";
export const END_CALL = "END_CALL";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const CONNECTED = "CONNECTED";
export const CONNECTION_FAILURE = "CONNECTION_FAILURE";