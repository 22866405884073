import Utils from "./utils";
import lobbyTemplate from "../pages/presentationLobby.html";
import { createLocalTracks } from "twilio-video";
import dayjs from "dayjs";
import i18n from "../config/i18n";
import camOn from "../images/camOn.html";
import camOff from "../images/camOff.html";
import micOn from "../images/micOn.html";
import micOff from "../images/micOff.html";

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;
const HOURS_IN_DAY = 24;

class PresentationLobby {
  constructor(container, onLobbyClose) {
    this.presentationId = Utils.getParameterValue("pid", null);
    this.localTracks = null;
    this.canJoin = false;

    this.onLobbyClose = onLobbyClose;
    this.container = document.querySelector(container);
    this.container.outerHTML = lobbyTemplate;
    this.container = document.querySelector(container);

    this.joinButton = document.querySelector(".btn-join-presentation");

    this.joinToPresentationLobby();
  }

  onJoin() {
    this.hasSentRequestToJoin = true;
    this.joinButton.setAttribute("disabled", "true");
    const buttonContainer = document.querySelector(".join-presentation");
    const loadingMessage = document.createElement("strong");
    loadingMessage.style.marginTop = "1em";
    loadingMessage.innerText = i18n.t("txtWaitingForAccept");
    buttonContainer.appendChild(loadingMessage);

    if (typeof this.onLobbyClose === "function")
      this.onLobbyClose(this.lobbyData);
    else this.closeLobby();
  }

  closeLobby() {
    this.container.setAttribute("data-disabled", "true");
    clearInterval(this.timeInterval);
    setTimeout(() => {
      this.container.remove();
    }, 1000);
  }

  async showLobby() {
    const { hasVideoInputDevices, hasAudioInputDevices } =
      await this.getDeviceInfo();

    navigator.mediaDevices
      .getUserMedia({
        audio: hasAudioInputDevices,
        video: hasVideoInputDevices,
      })
      .then((stream) => {
        this.fillDeviceInputs();
      })
      .catch((e) => {
        console.dir(e);
        document.querySelector(".select-devices").remove();
        createLocalTracks({
          audio: false,
          video: false,
        }).then((localTracks) => {
          this.localTracks = localTracks;
        });
      })
      .finally(() => {
        this.fillPresentationData();
        this.container.removeAttribute("data-disabled");
      });
  }

  showError({ message }) {
    const modalContainer = document.querySelector(".lobby-modal");

    const messageEl = document.createElement("strong");
    messageEl.classList.add("error-msg");
    messageEl.innerText = i18n.t(message);

    modalContainer.replaceChildren(messageEl);
    this.container.removeAttribute("data-disabled");
  }

  fillPresentationData() {
    const welcomeHeading = document.querySelector(".welcome-heading");
    if (this.lobbyData.clientDto.name)
      welcomeHeading.innerText = `${i18n.t("txtWelcome")} ${
        this.lobbyData.clientDto.name
      }`;
    else {
      this.showAuthForm();
    }

    const remainingTime = document.querySelector(".remaining-time");

    this.timeInterval = setInterval(() => {
      const time = this.getRemainingTime(this.lobbyData.date);
      remainingTime.innerText = time;
    }, 1000);
  }

  showAuthForm() {
    const userForm = document.querySelector(".user-data-form");
    userForm.style.display = "block";
    const clientNameInput = document.querySelector("#client-name");
    clientNameInput.placeholder = i18n.t(clientNameInput.placeholder);

    clientNameInput.addEventListener("change", (e) => {
      this.lobbyData = {
        ...this.lobbyData,
        clientDto: {
          name: e.target.value,
          phone: "000000000",
        },
      };
    });
  }

  async joinToPresentationLobby() {
    const hideLoader = Utils.showLoader();

    try {
      const res = await fetch(
        `${window.hubsUrl}/client/presentation/${this.presentationId}/join-presentation-lobby`,
        {
          method: "PATCH",
        }
      );
      const data = await res.json();

      if (!res.ok) throw new Error(data?.title);

      this.lobbyData = data;
      hideLoader();
      this.showLobby();
    } catch (e) {
      hideLoader();
      this.showError(e);
    }
  }

  async isPermissionDenied(permissionName) {
    if (navigator.permissions) {
      try {
        const result = await navigator.permissions.query({
          name: permissionName,
        });
        return result.state === "denied" || result.state === "prompt";
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  async getDeviceInfo() {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    const videoInputDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    const audioOutputDevices = devices.filter(
      (device) => device.kind === "audiooutput"
    );
    const hasAudioInputDevices = devices.some(
      (device) => device.kind === "audioinput"
    );
    const hasVideoInputDevices = devices.some(
      (device) => device.kind === "videoinput"
    );

    return {
      audioInputDevices,
      videoInputDevices,
      audioOutputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    };
  }

  async fillDeviceInputs() {
    const selectContainer = document.querySelector(".device-inputs");
    const {
      audioInputDevices,
      videoInputDevices,
      hasVideoInputDevices,
      hasAudioInputDevices,
    } = await this.getDeviceInfo();

    if (!hasVideoInputDevices && !hasAudioInputDevices) {
      document.querySelector(".select-devices").remove();
      return;
    }

    if (hasVideoInputDevices) {
      const camSelect = this.createSelectElement(
        videoInputDevices,
        async (deviceId) => {
          this.selectedVideo = deviceId;
          await this.setLocalTracks({ video: deviceId });
        },
        "camera"
      );

      selectContainer.appendChild(camSelect);
    }

    if (hasAudioInputDevices) {
      const micSelect = this.createSelectElement(
        audioInputDevices,
        async (deviceId) => {
          this.selectedAudio = deviceId;
          await this.setLocalTracks({ audio: deviceId });
        },
        "microphone"
      );
      selectContainer.appendChild(micSelect);
    }
  }

  createSelectElement(devices, onChange, type) {
    const selectContainer = document.createElement("div");
    selectContainer.classList.add("select-container");

    const iconContainer = document.createElement("div");
    iconContainer.classList.add("select-icon-container");
    const selectIcon = document.createElement("i");
    selectIcon.classList.add("fa", `fa-${type}`);
    iconContainer.appendChild(selectIcon);
    selectContainer.appendChild(iconContainer);

    onChange(devices[0].deviceId);
    const select = document.createElement("select");
    select.classList.add("select");
    devices.forEach((device) => {
      const option = document.createElement("option");
      option.value = device.deviceId;
      option.innerHTML = device.label;
      select.appendChild(option);
    });
    select.addEventListener("change", (e) => {
      onChange(e.target.value);
    });
    selectContainer.appendChild(select);
    this.createActionButton(type);

    return selectContainer;
  }

  createActionButton(type) {
    const container = document.querySelector(".room-actions");
    const icon = type === "microphone" ? micOn : camOn;
    const className = type === "microphone" ? "mute-button" : "cam-button";
    const trackKind = type === "microphone" ? "audio" : "video";

    const buttonEl = document.createElement("button");
    buttonEl.classList.add("action-button", className);
    buttonEl.innerHTML = icon;
    buttonEl.addEventListener(
      "click",
      this.toggleLocalTrack.bind(this, trackKind)
    );

    container.appendChild(buttonEl);
  }

  toggleLocalTrack(kind) {
    const track = this.getTracks().find((track) => track.kind === kind);
    if (!track) return;

    if (track.isEnabled) track.disable();
    else track.enable();

    switch (kind) {
      case "audio":
        document.querySelector(".mute-button").innerHTML = track.isEnabled
          ? micOn
          : micOff;
        break;
      case "video":
        document.querySelector(".cam-button").innerHTML = track.isEnabled
          ? camOn
          : camOff;
        this.displayCamPreview();
        break;
      default:
        break;
    }
  }

  async setLocalTracks({ audio, video }) {
    const localTracks = await createLocalTracks({
      audio:
        audio !== undefined
          ? audio
          : this.selectedAudio
          ? { deviceId: this.selectedAudio }
          : false,
      video:
        video !== undefined
          ? video
          : this.selectedVideo
          ? { deviceId: this.selectedVideo }
          : false,
    });
    this.localTracks = localTracks;
    this.displayCamPreview();
  }

  getTracks() {
    return this.localTracks;
  }

  displayCamPreview() {
    if (!this.localTracks) return;
    const container = document.querySelector(".video-container");
    const videoTrack = this.localTracks.find((track) => track.kind === "video");
    if (videoTrack?.isEnabled) container.replaceChildren(videoTrack.attach());
    else {
      const placeholder = document.createElement("div");
      placeholder.classList.add("video-placeholder");
      placeholder.innerText = i18n.t("txtCameraDisabled");
      container.replaceChildren(placeholder);
    }
  }

  getRemainingTime(date) {
    const parsedDate = dayjs(date);
    const now = dayjs();

    const durationInSeconds = parsedDate.diff(now, "s");

    const d = Math.floor(durationInSeconds / SECONDS_IN_DAY);
    const h = Math.floor((durationInSeconds / SECONDS_IN_HOUR) % HOURS_IN_DAY);
    const m = Math.floor(
      ((durationInSeconds - h * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE) %
        SECONDS_IN_MINUTE
    );
    const s = durationInSeconds % SECONDS_IN_MINUTE;
    const difference = [d, h, m, s].map((x) =>
      x > 0 && durationInSeconds > 0
        ? x.toLocaleString("pl-PL", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        : "00"
    );

    if (!this.canJoin && this.lobbyData.clientDto.name) {
      this.canJoin = true;
      this.joinButton.removeAttribute("disabled");
      this.joinButton.addEventListener("click", this.onJoin.bind(this));
    }

    return difference.join(":");
  }
}

export default PresentationLobby;
