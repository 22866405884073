import "App/css/style.scss";
import "App/css/animated.css";
import VirtualWalk from "App/js/preview.js";
import Controls from "App/js/controls.js";
import Chat from "App/js/chat.js";
import { Bus } from "App/js/simple-bus.js";
import MarzipanoLinker from "App/js/marzipano-view-linker.js";
import { INTRO_CLOSED } from "App/js/events.js";
import Store from "App/js/store.js";
import langs from "App/js/languages.js";
import Logo from "App/js/logo.js";
import Fullscreen from "./js/fullscreen";
import ajax from "App/js/ajax";
import Utils from "./js/utils";
import * as dayjs from "dayjs";
import { initTranslations } from "./config/i18n";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { VIEW_CHANGED } from "./js/events";
import ActivityTracker from "./js/activity-tracker";
import VirtualGuide from "./js/virtual-guide";
dayjs.locale(
  ["pl", "en"].includes(Utils.getProjectLang()) ? Utils.getProjectLang() : "en"
);

const app = async function () {
  function getFolderId() {
    const projectMatches = window.location.pathname.match(/\/([^\?#]*)|&|#|$/);
    if (!projectMatches[1]) {
      return;
    }
    return decodeURIComponent(projectMatches[1]);
  }

  function getFromConnection() {
    const value = Utils.getParameterValue("from");
    if (value !== "") {
      return value;
    } else {
      return "";
    }
  }

  window.ooc = {
    t: function (value, data) {
      var tpl = "";
      if (typeof langs[lang][value] !== "undefined") {
        tpl = langs[lang][value];
      } else {
        tpl = value;
      }

      if (data) {
        var parsed = tpl;
        Object.keys(data).forEach((key) => {
          const replacer = data[key];
          parsed = parsed.replace("{" + key + "}", replacer);
        });

        return parsed;
      }

      return tpl;
    },
  };

  const minimalMode = Utils.getParameterValue("min", null) !== null;
  const project = getFolderId();
  const lang = Utils.getProjectLang() == "en" ? "en" : "pl";
  const from = getFromConnection();

  const configUrl =
    window.storageUrl + "/" + project + "/config?ts=" + Date.now();
  let config = null;
  try {
    config = await ajax.get(configUrl);
  } catch (err) {
    console.warn(err);
    config = err.request.status;
  }

  try {
    const embedDataResponse = await fetch(
      `${window.apiUrl}/api/group/user/${config.UserId}/embed-data`
    );
    const embedData = await embedDataResponse.json();
    config.OwnerAvatarUrl = embedData?.ownerAvatarUrl;
    config.UserName = embedData?.userName;
  } catch (err) {
    console.warn(err);
  }

  const autorotate =
    Utils.getParameterValue("na", null) == null && config.AutorotationEnabled;
  const noChat = !(
    Utils.getParameterValue("nc", false) == null && config.ChatEnabled
  );
  const noLead = !(
    Utils.getParameterValue("hl", false) == null && config.ContactFormEnabled
  );
  const showNadir =
    Utils.getParameterValue("hn", false) == null && !config.HideNadir;
  const webView = Utils.getParameterValue("wv", false) == null;

  const viewer = new VirtualWalk(
    project,
    Store,
    {
      minimalMode,
      autorotate,
      showNadir,
    },
    config
  );
  if (viewer.viewer) {
    const controls = new Controls(viewer.viewer);
    Bus.subscribe(INTRO_CLOSED, () => {
      var folderId = project.match(
        /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}-([0-9]+)$/
      )[1];

      // show logo
      if (!minimalMode) {
        const logo = new Logo(
          viewer.project,
          viewer.project.Logo ? "Logo" : "GroupLogoUrl",
          ".logo"
        );
        logo.show();
      }

      if (webView) {
        new Fullscreen(".fullscreen");
      } else {
        document.querySelector(".fullscreen").style.display = "none";
      }

      // enable chat module
      const chat = new Chat(".chat2", project, folderId, Store, from, {
        leadPage: viewer.project.IsLeadPage,
        //leadPage: true,
        mls: minimalMode,
        nc: noChat,
        nl: noLead,
        ownerAvatar: config?.OwnerAvatarUrl,
      });

      if (!minimalMode) {
        const linker = new MarzipanoLinker();
        chat.setChatBtnClickCallback(function () {
          viewer.stopAutorotate();
        });
        linker.setViewer(viewer, (data) => {
          Bus.post(VIEW_CHANGED, data);
          chat.sendCaptureData.bind(chat);
        });
        chat.linker = linker;
      }

      // Activity tracker
      const activityTracker = new ActivityTracker(config);
      // Virtual guide
      if (
        !noChat &&
        !Utils.getParameterValue("pid", null) &&
        config?.HasVirtualGuide
      ) {
        const virtualGuide = new VirtualGuide(
          chat,
          activityTracker,
          viewer,
          config
        );
      }
    });
    viewer.load();
  }
};

const start = function () {
  var started = false,
    hiddenPropName,
    visibilityChangeEventName;
  if (typeof document.hidden !== "undefined") {
    hiddenPropName = "hidden";
    visibilityChangeEventName = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hiddenPropName = "msHidden";
    visibilityChangeEventName = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hiddenPropName = "webkitHidden";
    visibilityChangeEventName = "webkitvisibilitychange";
  }

  var innerStart = function (event) {
    var winHeight = window.innerHeight;
    var docHeight = document.body.scrollHeight;

    //console.log('innerStart', { event, started, winHeight, docHeight });

    if (
      started === false &&
      hiddenPropName &&
      document[hiddenPropName] === false &&
      winHeight > 0 &&
      docHeight > 0
    ) {
      //console.log('app start')
      fetch("/initial-settings.json")
        .then((data) => data.json())
        .then((data) => {
          Object.keys(data || {}).forEach((key) => {
            window[key.charAt(0).toLowerCase() + key.slice(1)] = data[key];
          });
          initTranslations().then((value) => app());
          started = true;
        });
    } else {
      //console.log('app started or iframe is hidden')
    }
  };

  //console.log('start', { hiddenPropName, visibilityChangeEventName, started });

  if (hiddenPropName && visibilityChangeEventName) {
    document.addEventListener(visibilityChangeEventName, innerStart);
    window.addEventListener("resize", innerStart);
  }
  innerStart();
};

if (typeof document.addEventListener === "undefined") {
  console.error("You have an old browser behind.");
} else {
  document.addEventListener("DOMContentLoaded", start);
}
