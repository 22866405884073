import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const initTranslations = () => {
    const promise = i18n
        .use(Backend)
        .use(LanguageDetector)
        .init({
            fallbackLng: "en",
            debug: false,
            detection: {
                order: ["navigator", "querystring", "cookie"],
                lookupQuerystring: 'lang',
                cache: ["cookie"],
            },
            interpolation: {
                escapeValue: false,
            },
            backend: {
                loadPath: ([lng]) => {
                // console.log(lng);
                return `${window.apiUrl}/api/translations/translations/${lng}.json?applicationType=2`;
                },
                parse: (data) => {
                const parsed = JSON.parse(data);
                const merged = Object.entries(parsed).reduce(
                    (prev, curr) => Object.assign(prev, curr[1]),
                    {}
                );
                // console.log("Parsed data", merged);
                return merged;
                },
            },
        });
    
    customElements.define('i18n-t', class extends HTMLElement {
        connectedCallback() {
            this.innerHTML = i18n.t(this.innerHTML);
        }
    })
    
    return promise;
};

export default i18n;
