class Ajax {
    get(url) {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.onreadystatechange = function() {
                if (request.readyState == 4) {
                    if (request.status == 200) {
                        const response = JSON.parse(request.response);
                        resolve(response);
                    } else {
                        reject({
                            message: window.ooc.t("txtServerNotAvailable"),
                            request: request
                        });
                    }
                }
            };
            request.open('GET', url, true);
            request.send();
        });
    }
}

export default new Ajax();
