import micOff from "../../images/micOff.html";
import micOn from "../../images/micOn.html";
import camOff from "../../images/camOff.html";
import camOn from "../../images/camOn.html";

export const trackIndicators = {
  audio: {
    className: "muted-indicator",
    disabled: micOff,
    enabled: micOn,
  },
  video: {
    className: "nocam-indicator",
    disabled: camOff,
    enabled: camOn,
  },
};
