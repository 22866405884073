import participantsTemplate from "../pages/presentationParticipants.html";
import { connect } from "twilio-video";
import micOff from "../images/micOff.html";
import micOn from "../images/micOn.html";
import camOff from "../images/camOff.html";
import camOn from "../images/camOn.html";
import { trackIndicators } from "./utils/trackIndicators";
import { Bus } from "./simple-bus";
import { SCENE_CHANGED, VIEW_CHANGED } from "./events";
import { throttle } from "./utils/throttle";
import utils from "./utils";
import i18n from "../config/i18n";

const THROTTLE_DELAY = 1000;

class VirtualPresentation {
  constructor(data, tracks, connection, roomId, container) {
    this.presentationId = utils.getParameterValue("pid", null);
    this.roomData = data;
    this.tracks = tracks;
    this.connection = connection;
    this.roomId = roomId;

    this.hasControl = false;
    this.isMicMuted =
      tracks?.length > 0
        ? !tracks.find((track) => track.kind === "audio").isEnabled
        : true;
    this.isCamDisabled =
      tracks?.length > 0 && tracks.find((track) => track.kind === "video")
        ? !tracks.find((track) => track.kind === "video").isEnabled
        : true;

    this.container = document.querySelector(container);
    this.container.outerHTML = participantsTemplate;
    this.container = document.querySelector(container);

    this.joinRoom(data);
  }

  async joinRoom({ roomName, token }) {
    const config = {
      room: roomName,
      name: roomName,
      ...(this.tracks && {
        maxAudioBitrate: 16000,
        tracks: this.tracks,
        video: {
          height: 480,
          width: 640,
          frameRate: 24,
        },
      }),
    };

    const hideLoader = utils.showLoader();

    try {
      const room = await connect(token, config);

      // styling
      const viewport = document.querySelector(".viewport");
      const scenePickerButton = document.querySelector(".photoname-button");
      document.querySelector(".logo").style.display = "none";
      viewport.setAttribute("disabled", "true");
      scenePickerButton.setAttribute("disabled", "disabled");

      this.handleConnectedParticipant(room.localParticipant);
      room.participants.forEach(this.handleConnectedParticipant, this);
      room.on(
        "participantConnected",
        this.handleConnectedParticipant.bind(this)
      );

      // handle cleanup when a participant disconnects
      room.on("participantDisconnected", this.handleDisconnectedParticipant);
      window.addEventListener("pagehide", () => room.disconnect());
      window.addEventListener("beforeunload", () => room.disconnect());

      this.room = room;

      const muteButton = document.querySelector(".mute-button");
      if (this.tracks.find((track) => track.kind === "audio")) {
        muteButton.addEventListener("click", this.toggleMute.bind(this));
        muteButton.removeAttribute("disabled");
      }
      if (this.isMicMuted) muteButton.innerHTML = micOff;

      const camButton = document.querySelector(".cam-button");
      if (this.tracks.find((track) => track.kind === "video")) {
        camButton.addEventListener("click", this.toggleCam.bind(this));
        camButton.removeAttribute("disabled");
      }
      if (this.isCamDisabled) camButton.innerHTML = camOff;

      document
        .querySelector(".leave-button")
        .addEventListener("click", this.endCall.bind(this));

      this.container.removeAttribute("data-disabled");
      this.handleEvents();
      hideLoader();
    } catch (error) {
      hideLoader();
      this.showError(error);
      console.dir(error);
    }
  }

  handleConnectedParticipant(participant) {
    this.toggleControl(false);
    const container = document.querySelector(".video-container");
    // create a div for this participant's tracks
    const participantDiv = document.createElement("div");
    participantDiv.setAttribute("id", participant.identity);

    const indicatorContainer = document.createElement("div");
    indicatorContainer.classList.add("indicator-container");

    participantDiv.append(indicatorContainer);
    container.appendChild(participantDiv);

    // iterate through the participant's published tracks and
    // call `handleTrackPublication` on them
    participant.tracks.forEach((trackPublication) => {
      this.handleTrackPublication(trackPublication, participant);
    }, this);

    // listen for any new track publications
    participant.on("trackPublished", (trackPublication) =>
      this.handleTrackPublication.bind(this, trackPublication, participant)
    );
  }

  handleTrackPublication(trackPublication, participant) {
    function displayTrack(track) {
      // append this track to the participant's div and render it on the page
      const participantDiv = document.getElementById(participant.identity);

      // track.attach creates an HTMLVideoElement or HTMLAudioElement
      // (depending on the type of track) and adds the video or audio stream
      if (participantDiv) participantDiv.append(track?.attach());

      this.handleTrackState(participant, track);

      // Listen for track mute
      track.on(
        "disabled",
        this.handleTrackState.bind(this, participant, track)
      );
      track.on("enabled", this.handleTrackState.bind(this, participant, track));
    }

    // check if the trackPublication contains a `track` attribute. If it does,
    // we are subscribed to this track. If not, we are not subscribed.
    if (trackPublication.track) {
      displayTrack.call(this, trackPublication.track);
    }

    // listen for any new subscriptions to this track publication
    trackPublication.on("subscribed", displayTrack.bind(this));
  }

  handleDisconnectedParticipant(participant) {
    // stop listening for this participant
    participant.removeAllListeners();
    // remove this participant's div from the page
    const participantDiv = document.getElementById(participant.identity);
    participantDiv.remove();
  }

  toggleControl(value) {
    this.hasControl = value ?? !this.hasControl;
    const controlIndicator = document.querySelector(".control-indicator");
    const viewport = document.querySelector(".viewport");
    const scenePickerButton = document.querySelector(".photoname-button");

    if (this.hasControl) {
      controlIndicator.removeAttribute("data-disabled");
      viewport.removeAttribute("disabled");
      scenePickerButton.removeAttribute("disabled");
    }
    if (!this.hasControl) {
      controlIndicator.setAttribute("data-disabled", "true");
      viewport.setAttribute("disabled", "true");
      scenePickerButton.setAttribute("disabled", "disabled");
    }
  }

  toggleMute() {
    this.room.localParticipant.audioTracks.forEach(({ track }) => {
      if (this.isMicMuted) {
        track.enable();
        document.querySelector(".mute-button").innerHTML = micOn;
      }
      if (!this.isMicMuted) {
        track.disable();
        document.querySelector(".mute-button").innerHTML = micOff;
      }
      this.isMicMuted = !this.isMicMuted;
    }, this);
  }

  toggleCam() {
    this.room.localParticipant.videoTracks.forEach(({ track }) => {
      if (this.isCamDisabled) {
        track.enable();
        document.querySelector(".cam-button").innerHTML = camOn;
      }
      if (!this.isCamDisabled) {
        track.disable();
        document.querySelector(".cam-button").innerHTML = camOff;
      }
      this.isCamDisabled = !this.isCamDisabled;
    }, this);
  }

  endCall() {
    this.room.disconnect();
    this.container.remove();
    document.querySelector(".logo").style.display = "block";
    document.querySelector(".viewport").removeAttribute("disabled");
    document.querySelector(".photoname-button").removeAttribute("disabled");

    this.connection.off("ViewChanged");
    this.connection.off("SceneChanged");
    this.connection.off("ControlRevoked");
    this.connection.off("ControlGained");
    this.connection.off("PresentationFinished");

    this.tracks.forEach((track) => track.stop());
  }

  handleTrackState(participant, track) {
    const participantDiv = document.getElementById(participant.identity);
    if (!participantDiv) return;

    const indicator = trackIndicators[track.kind];
    if (track.isEnabled) {
      this.removeDisabledTrackIndicator(participantDiv, indicator);
    } else {
      this.createDisabledTrackIndicator(participantDiv, indicator);
    }
    if (track.kind === "video")
      this.swapVideoThumbnail(
        participantDiv,
        participant.identity.substr(0, 1),
        !track.isEnabled
      );
  }

  swapVideoThumbnail(participantDiv, participantId, isDisabled) {
    const videoEl = participantDiv.querySelector("video");
    const placeholderEl = participantDiv.querySelector(".webcam-placeholder");
    if (isDisabled) {
      videoEl.style.display = "none";

      if (placeholderEl) placeholderEl.style.display = "grid";
      else {
        const placeholder = document.createElement("div");
        placeholder.classList.add("webcam-placeholder");
        placeholder.innerText = participantId;
        participantDiv.appendChild(placeholder);
      }
    } else {
      videoEl.style.display = "block";
      if (placeholderEl) placeholderEl.style.display = "none";
    }
  }

  createDisabledTrackIndicator(participantDiv, indicator) {
    const container = participantDiv.querySelector(".indicator-container");
    const disabledIndicator = document.createElement("div");
    disabledIndicator.classList.add("disabled-indicator", indicator.className);
    disabledIndicator.innerHTML = indicator.disabled;

    container.appendChild(disabledIndicator);
  }

  removeDisabledTrackIndicator(participantDiv, indicator) {
    const disabledIndicator = participantDiv.querySelector(
      `.${indicator.className}`
    );

    if (disabledIndicator) disabledIndicator.remove();
  }

  handleEvents() {
    Bus.subscribe(VIEW_CHANGED, this.changeView());
    Bus.subscribe(SCENE_CHANGED, this.changeScene());
  }

  changeView() {
    return throttle((data) => {
      if (!this.hasControl) return;

      this.connection
        .invoke("ChangeView", {
          roomId: this.roomId,
          presentaionId: this.presentationId,
          ...data,
        })
        .catch((e) => {
          console.dir(e);
        });
    }, THROTTLE_DELAY);
  }

  changeScene() {
    return throttle((data) => {
      if (!this.hasControl) return;
      this.connection
        .invoke("ChangeScene", {
          roomId: this.roomId,
          presentaionId: this.presentationId,
          sceneId: data.photoId,
        })
        .catch((e) => {
          console.dir(e);
        });
    }, THROTTLE_DELAY);
  }

  showError({ message }) {
    const backdrop = document.createElement("div");
    backdrop.classList.add("backdrop");

    const messageContainer = document.createElement("div");
    messageContainer.classList.add("lobby-modal");
    messageContainer.style.flexDirection = "column";
    messageContainer.style.gap = "1em";

    const messageEl = document.createElement("h3");
    messageEl.innerText = message;
    messageContainer.appendChild(messageEl);

    const refreshButton = document.createElement("button");
    refreshButton.classList.add("btn-primary");
    refreshButton.innerText = i18n.t("txtRefreshPage");
    refreshButton.addEventListener(
      "click",
      window.location.reload.bind(window.location)
    );
    messageContainer.appendChild(refreshButton);

    document.body.appendChild(backdrop);
    document.body.appendChild(messageContainer);
  }
}

export default VirtualPresentation;
