import dayjs from "dayjs";
import i18n from "../config/i18n";
import { CHAT_STARTED, FIRST_VISIT_SCHENE, SCENE_CHANGED } from "./events";
import utils from "./utils";
import { Speech } from "./speech";

export default class VirtualGuide {
  constructor(chat, activityTracker, walk, config) {
    this.config = config;
    this.chat = chat;
    this.activityTracker = activityTracker;
    this.messages = {};
    this.language = utils.getProjectCreationLanguage();
    this.isGuideActive = true;

    this.startTime = dayjs();
    this.systeMessage1PhotoId = null;
    this.systeMessage2PhotoId = null;
    this.systeMessage3PhotoId = null;

    this.fetchGuideMessages();
    this.speech = new Speech(config);

    this.systemMessages = new SystemMessageQueue(chat, config, this.speech);

    if (
      this.config.VirtualGuideMessages.Photos.filter(
        (photo) => !!photo.Messages.length
      ).length < 3
    ) {
      this.isGuideActive = false;
    }

    const firstPhotoId =
      this.config.StartingPhotoId || this.config.Photos?.[0].Id;

    // Add message for starting photo to queue
    setTimeout(() => {
      if (
        this.isGuideActive &&
        !this.activityTracker.isChatStarted &&
        firstPhotoId in this.messages &&
        this.messages[firstPhotoId].length &&
        this.messages[firstPhotoId][0].Content
      ) {
        this.systemMessages.addToQueue({
          id: firstPhotoId,
          message: this.messages[firstPhotoId][0]?.Content,
          sentByClient: true,
          userName: this.config?.UserName,
          type: "tip",
          isSystemMessage: true,
          withLector: true,
          messageDelay: this.config?.Lector ? 0 : undefined,
        });
      }
    }, 1000);

    // Add first question to queue after 25s
    setTimeout(async () => {
      if (this.isGuideActive) {
        this.sendSystemMessage1();
        this.systeMessage1PhotoId = this.activityTracker.currentSceneId;
      }
    }, 25000);

    // Add second question to queue after 60s
    setTimeout(async () => {
      if (
        this.isGuideActive &&
        activityTracker.currentSceneId !== this.systeMessage1PhotoId
      ) {
        this.sendSystemMessage2();
        this.systeMessage2PhotoId = this.activityTracker.currentSceneId;
      }
    }, 60000);

    // Add third question to queue after 90s
    setTimeout(async () => {
      if (
        this.isGuideActive &&
        this.systeMessage2PhotoId != null &&
        activityTracker.currentSceneId !== this.systeMessage2PhotoId
      ) {
        this.sendSystemMessage3();
        this.systeMessage3PhotoId = this.activityTracker.currentSceneId;
      }
    }, 90000);

    // After 30s of inactivity, suggest to visit a unvisited room
    this.activityTracker.afterLastActivity(
      null,
      () => {
        if (
          !this.activityTracker.isMediaPreviewOpen &&
          !this.activityTracker.isChatStarted &&
          this.isGuideActive
        ) {
          const room = activityTracker.getUnvisitedRoom();
          if (room) {
            this.systemMessages.addToQueueOnTop({
              // Add on top of the queue
              id: "room-suggestion",
              message: i18n
                .t("txtVirtualGuideVisitRoomSuggestion", { lng: this.language })
                .replace("<room>", room.Name),
              sentByClient: true,
              userName: config?.UserName,
              disableAutoHiding: true,
              onClick: () => {
                walk.switchSceneByUser(room, null, true);
              },
              type: "tip",
              isSystemMessage: true,
            });
          }
        }
      },
      30000,
      false
    );

    // After changing the scene to unvisited room, add a message for that photo to the queue
    this.activityTracker.afterLastActivity(
      FIRST_VISIT_SCHENE,
      ({ photoId }) => {
        if (this.systemMessages.getSize() > 0) {
          this.handleVisitNewScene(photoId);
        } else {
          // If message would be sent immediately, wait 2s after photo change for better user experience
          setTimeout(() => {
            this.handleVisitNewScene(photoId);
          }, 2000);
        }
      }
    );

    // Stop the guide after chat started
    this.activityTracker.afterLastActivity(CHAT_STARTED, () => {
      this.systemMessages.clearQueue();
      this.speech.stop();
    });
  }

  handleVisitNewScene(photoId) {
    if (
      !this.activityTracker.isChatStarted &&
      photoId in this.messages &&
      this.messages[photoId].length &&
      this.messages[photoId]?.[0]?.Content &&
      this.isGuideActive
    ) {
      this.systemMessages.addToQueue({
        id: photoId,
        message: this.messages[photoId][0]?.Content,
        sentByClient: true,
        userName: this.config?.UserName,
        type: "tip",
        isSystemMessage: true,
        withLector: true,
        messageDelay: this.config?.Lector ? 0 : undefined,
      });
    }

    if (
      this.systeMessage2PhotoId == null &&
      dayjs().diff(this.startTime, "s") > 60 &&
      photoId !== this.systeMessage1PhotoId
    ) {
      this.sendSystemMessage2();
      this.systeMessage2PhotoId = this.activityTracker.currentSceneId;
    } else if (
      this.systeMessage2PhotoId != null &&
      this.systeMessage3PhotoId == null &&
      dayjs().diff(this.startTime, "s") > 90 &&
      photoId !== this.systeMessage2PhotoId
    ) {
      this.sendSystemMessage3();
      this.systeMessage3PhotoId = this.activityTracker.currentSceneId;
    }
  }

  async sendSystemMessage1() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.systemMessages.addToQueue({
      id: "system-message-1",
      message: i18n.t("txtVirtualGuideSystemMessage1Question", {
        lng: this.language,
      }),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      withQuestion: true,
      isSystemMessage: true,
      positiveButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage1PositiveResponse"
        )
      ),
      negativeButtonAction: async () => {
        this.handleActionButtonClick.call(
          this,
          await this.getReponseTextDependingOnAgentStatus(
            "txtVirtualGuideSystemMessage1NegativeResponse"
          ),
          false,
          true
        )();
        this.noteVirtualGuideInteraction();
      },
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage1PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage1NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async sendSystemMessage2() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.systemMessages.addToQueue({
      message: i18n.t("txtVirtualGuideSystemMessage2Question", {
        lng: this.language,
      }),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      isSystemMessage: true,
      withQuestion: true,
      positiveButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage2PositiveResponse"
        ),
        true
      ),
      negativeButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage2NegativeResponse"
        )
      ),
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage2PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage2NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async sendSystemMessage3() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.systemMessages.addToQueue({
      delayMessage: false,
      message: i18n.t("txtVirtualGuideSystemMessage3Question", {
        lng: this.language,
      }),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      isSystemMessage: true,
      withQuestion: true,
      positiveButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage3PositiveResponse"
        ),
        true
      ),
      negativeButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage3NegativeResponse"
        ),
        true
      ),
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage3PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage3NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async getReponseTextDependingOnAgentStatus(key) {
    const noChatResponse = i18n.t(key, {
      lng: this.language,
    });
    const activeChatResponse = i18n.t(`${key}ActiveChat`, {
      lng: this.language,
    });
    if (activeChatResponse.startsWith("txt")) {
      return noChatResponse;
    } else {
      const isAgentAvailable = await this.getAgentAvailability();
      if (isAgentAvailable) {
        return activeChatResponse;
      } else {
        return noChatResponse;
      }
    }
  }

  async getAgentAvailability() {
    const { isAgentAvailable } = await fetch(
      `${window.apiUrl}/api/forms/${this.chat.folderId}/get-agent-availability`
    ).then((data) => data.json());
    return isAgentAvailable;
  }

  handleActionButtonClick(
    message,
    withContactForm = false,
    stopVirtualGuide = false
  ) {
    if (!message) return;
    if (stopVirtualGuide) {
      this.isGuideActive = false;
    }
    setTimeout(() => {
      this.chat.onMessage(
        {
          message,
          sent: new Date().toISOString(),
          sentByClient: true,
          userName: this.config?.UserName,
          hideAfter: 6000,
          disableAutoHiding: withContactForm,
          type: "tip",
          isSystemMessage: true,
          withContactForm,
        },
        false
      );
    }, 1000);
  }

  fetchGuideMessages() {
    this.messages = this.config?.VirtualGuideMessages?.Photos?.reduce(
      (prev, curr) => {
        prev[curr?.PhotoId] = curr?.Messages;
        return prev;
      },
      {}
    );
  }

  async noteVirtualGuideInteraction() {
    await fetch(
      `${window.apiUrl}/api/project/stats/${this.chat.folderId}/virtual-guide-interact`,
      {
        method: "PATCH",
      }
    );
  }
}

class SystemMessageQueue {
  constructor(chat, config, speech, { messageDelay = 3000 } = {}) {
    this.chat = chat;
    this.config = config;
    this.speech = speech;
    this.alreadyPlayed = [];
    this.queue = [];
    this.nextUpdate = null;
    this.current = null;
    this.messageDelay = messageDelay;
  }

  addToQueue(message) {
    this.queue.push(message);
    this.tryNext();
  }

  addToQueueOnTop(message) {
    this.queue.unshift(message);
    this.tryNext();
  }

  tryNext() {
    if (this.current !== null || this.queue.length == 0) return;

    this.current = this.queue.shift();
    if (this.alreadyPlayed.indexOf(this.current.id) >= 0) {
      setTimeout(this.finished.bind(this), 0); //avoid stack overflow
      return;
    }
    this.alreadyPlayed.push(this.current.id);
    let duration = 0 + (this.current?.messageDelay ?? this.messageDelay);
    if (this.config?.Lector && this.current?.withLector) {
      duration =
        this.speech.play(this.current.id) +
        (this.current?.messageDelay ?? this.messageDelay);
      console.log("Playing lector. Duration:", duration, "Delay:", (this.current?.messageDelay ?? this.messageDelay));
    } else if (this.current.duration != null) {
      duration =
        this.current.duration +
        (this.current?.messageDelay ?? this.messageDelay);
    }
    this.chat.onMessage({
      delayMessage: false,
      sent: new Date().toISOString(),
      ...this.current,
      duration,
    });
    this.nextUpdate = setTimeout(this.finished.bind(this), duration);
  }

  finished() {
    this.current = null;
    this.tryNext();
  }

  clearQueue() {
    clearTimeout(this.nextUpdate);
    this.queue.length = 0;
    this.current = null;
  }

  getSize() {
    return this.queue.length;
  }
}
