import floorplanWindowTemplate from "App/pages/floorplanWindow.html";
import floorplanTabTemplate from "App/pages/floorplanTab.html";
import floorplanLevelTemplate from "App/pages/floorplanLevel.html";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

export default class Floorplan {
    constructor(container, photos) {
        this.container = container;
        this.photos = photos;
        this.events = {};
        this.levels = [];
        this.points = [];
        this.onPointClickEvent = new CustomEvent("onPointClick");
    }

    addEventListener(name, handler) {
        if (this.events.hasOwnProperty(name)) {
            this.events[name].push(handler);
        } else {
            this.events[name] = [handler];
        }
    }

    removeEventListener(name, handler) {
        if (!this.events.hasOwnProperty(name)) {
            return;
        }
        var index = this.events[name].indexOf(handler);
        if (index != -1) {
            this.events[name].splice(index, 1);
        }
    }

    fireEvent(name, args) {
        if (!this.events.hasOwnProperty(name)) {
            return;
        }
        if (!args || !args.length) {
            args = [];
        }
        var evs = this.events[name], l = evs.length;
        for (var i = 0; i < l; i++) {
            evs[i].apply(null, args);
        }
    }

    buildWindow() {
        let floorplanElement = document.createElement('div');
        floorplanElement.innerHTML = floorplanWindowTemplate;
        return floorplanElement.firstChild;
    }

    buildTab(level, index) {
        const tabElementDiv = document.createElement('div');
        tabElementDiv.innerHTML = floorplanTabTemplate;
        const tabElement = tabElementDiv.firstChild;
        tabElement.setAttribute("id", `floorplanTab-${level.Id}`);
        const link = tabElement.querySelector(".floorplan-tab-link");
        link.innerHTML = (index+1).toString();
        link.addEventListener("click", function (e) {
            e.preventDefault();
            this.onTabClick(level.Id);
        }.bind(this));
        return tabElement;
    }

    buildLevelPoint(point, levelId) {
        const photo = this.getPhotoById(point.PhotoId);
        if (photo) {
            const pointElementDiv = document.createElement('div');
            pointElementDiv.innerHTML = `<li class="floorplan-point"><div class="point"></div></li>`;
            const pointElement = pointElementDiv.firstChild;
            pointElement.setAttribute("id", `floorplanPoint-${point.PhotoId}`);
            pointElement.dataset.label = photo.Name;
            pointElement.style.left = `${point.X}%`;
            pointElement.style.top = `${point.Y}%`;
            pointElement.addEventListener("click", function (e) {
                this.onPointClick(point.PhotoId);
            }.bind(this));
            this.points.push({
                ...point,
                level: levelId,
                element: pointElement,
            });
            return pointElement;
        }
        return false;
    }

    buildLevel(level) {
        const levelElementDiv = document.createElement('div');
        levelElementDiv.innerHTML = floorplanLevelTemplate;
        const levelElement = levelElementDiv.firstChild;
        levelElement.setAttribute("id", `floorplanLevel-${level.Id}`);
        levelElement.querySelector(".floorplan-level-img").setAttribute("src", level.Url);
        const levelPoints = levelElement.querySelector(".floorplan-points");
        for (var i = 0; i < level.Points.length; i++) {
            const point = level.Points[i];
            const pointElement = this.buildLevelPoint(point, level.Id);
            if (pointElement) {
                levelPoints.appendChild(pointElement);
            }
        }
        return levelElement;
    }

    rebuildLevels(levels) {
        this.levels = [];
        this.points = [];
        this.floorplanTabs.querySelectorAll(".floorplan-tab").forEach(function (el) {
            el.remove();
        });
        this.floorplanLevels.querySelectorAll(".floorplan-level").forEach(function (el) {
            el.remove();
        });
        for (var i = 0; i < levels.length; i++) {
            const level = levels[i];
            const florplanTab = this.buildTab(level, i);
            const florplanLevel = this.buildLevel(level);
            this.floorplanTabs.appendChild(florplanTab);
            this.floorplanLevels.appendChild(florplanLevel);
            this.levels.push({
                ...level,
                tab: florplanTab,
                level: florplanLevel,
            });
        }

        this.floorplanLevels.querySelectorAll(".floorplan-level .floorplan-points").forEach(pointsContainer => {
            pointsContainer.querySelectorAll('.floorplan-point').forEach(point => {
                const label = point.dataset.label;
                tippy(point, {
                    content: label,
                    arrow: false,
                    theme: 'green',
                    appendTo: 'parent',
                    placement: 'auto',
                    offset: [0, 15],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                options: {
                                    boundary: pointsContainer,
                                },
                            },
                            {
                                name: 'computeStyles',
                                options: {
                                    roundOffsets: ({ x, y }) => ({
                                        x: Math.round(x + 10),
                                        y: Math.round(y + 10),
                                    }),
                                },
                            },
                        ],
                    }
                });
            });
        });
    }

    create(levels) {
        const floorplan = this.buildWindow();

        const floorplanTabs = floorplan.querySelector(".floorplan-tabs ul");
        const floorplanLevels = floorplan.querySelector(".floorplan-levels ul");

        this.floorplan = floorplan;
        this.floorplanTabs = floorplanTabs;
        this.floorplanLevels = floorplanLevels;

        const preparedLevels = levels.filter(level => Boolean(level));
        this.rebuildLevels(preparedLevels);

        if (this.levels.length > 0) {
            this.showLevel(this.levels[0].Id);
        }
        this.container.appendChild(floorplan);
    }

    show() {
        if (this.levels.length) {
            this.floorplan.classList.remove("hidden");
        }
    }

    showLevel(id) {
        const tab = this.floorplanTabs.querySelector(`#floorplanTab-${id}`);
        const level = this.floorplanLevels.querySelector(`#floorplanLevel-${id}`);
        this.floorplanTabs.querySelectorAll(".floorplan-tab").forEach(function (el) {
            el.classList.remove("active");
        });
        this.floorplanLevels.querySelectorAll(".floorplan-level").forEach(function (el) {
            el.classList.add("hidden");
        });
        if (tab) {
            tab.classList.add("active");
        }
        if (level) {
            level.classList.remove("hidden");
        }
    }

    switchPoint(id, initial) {
        const withSwitchLevel = initial || false;
        this.floorplan.querySelectorAll(".floorplan-point").forEach(function (el) {
            el.classList.remove("active");
        });
        const point = this.points.find(function (point) {
            return point.PhotoId === id;
        }.bind(this));
        if (point) {
            point.element.classList.add("active");
            if (withSwitchLevel) {
                this.showLevel(point.level);
            }
        }
    }

    onTabClick(id) {
        this.showLevel(id);
    }

    onPointClick(id) {
        this.switchPoint(id);
        this.fireEvent("onPointClick", [id]);
    }

    getPhotoById(id) {
        return this.photos.find(photo => photo.Id === id);
    }
}
