import Marzipano from 'marzipano';

export default class Controls {

    constructor(viewer) {
        // DOM elements for view controls.
        const viewUpElement = document.querySelector('#viewUp');
        const viewDownElement = document.querySelector('#viewDown');
        const viewLeftElement = document.querySelector('#viewLeft');
        const viewRightElement = document.querySelector('#viewRight');
        const viewInElement = document.querySelector('#viewIn');
        const viewOutElement = document.querySelector('#viewOut');

        // Dynamic parameters for controls.
        const velocity = 0.7;
        const friction = 3;

        // Associate view controls with elements.
        const controls = viewer.controls();
        controls.registerMethod('upElement', new Marzipano.ElementPressControlMethod(viewUpElement, 'y', -velocity, friction), true);
        controls.registerMethod('downElement', new Marzipano.ElementPressControlMethod(viewDownElement, 'y', velocity, friction), true);
        controls.registerMethod('leftElement', new Marzipano.ElementPressControlMethod(viewLeftElement, 'x', -velocity, friction), true);
        controls.registerMethod('rightElement', new Marzipano.ElementPressControlMethod(viewRightElement, 'x', velocity, friction), true);
        controls.registerMethod('inElement', new Marzipano.ElementPressControlMethod(viewInElement, 'zoom', -velocity, friction), true);
        controls.registerMethod('outElement', new Marzipano.ElementPressControlMethod(viewOutElement, 'zoom', velocity, friction), true);
    }
}
