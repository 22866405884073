import Store from "App/js/store.js";
export default class MarzipanoLinker {
    constructor(walk) {
        this.walk = walk;
        this.interval = null;
    }

    set(payload) {
        var scene = this.walk.viewer.scene();
        scene.lookTo(payload);
    }

    get() {
        var view = this.walk.viewer.view();
        var data = {
            yaw: view.yaw(),
            pitch: view.pitch(),
            fov: view.fov(),
            scene: Store.currentSceneId,
            target: Store.recentTargetId
        };
        return data;
    }

    requestChangeScene(data) {
        this.walk.requestSwitchScene(data, true);
    }
    setViewer(walk, publisher) {
        this.walk = walk;
        this.walk.viewer.addEventListener("viewChange", () => {
            this.throttle(publisher);
        });
    }
    measure() {
        var now = new Date().getTime();
        if (this.lastCheck) {
            var temp = 1 / ((now - this.lastCheck) / 1000);
            this.range = this.range || [];
            if (this.range.length == 20) {
                this.range.shift();
            }
            this.range.push(temp);
            var sum = this.range.reduce((a, b) => a + b);
            console.log(sum / this.range.length);
        }
        this.lastCheck = now;
    }
    sample(publisher) {
        this.last = this.get();
        if (this.timeout) {
            return;
        }
        this.timeout = setTimeout(() => {
            //this.measure();
            publisher(this.last);
            this.timeout = null;
        }, 200);
    }

    throttle(publisher) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(() => {
            //this.measure();
            publisher(this.get());
            this.timeout = null;
        }, 200);
    }
}
